import React from 'react'
import s from './Text.module.scss'
import { Container } from '~views/shared/components'
import { ITextSection } from '~models'
import { createMarkup } from '~libs/utils'
import { Script } from 'gatsby'

interface Props {
  section: ITextSection
}
export class TextSection extends React.Component<Props> {
  render() {
    const { section } = this.props
    const { texts } = section
    const { wysiwygText } = texts[0]

    const match = wysiwygText && wysiwygText.match(/<script>([\s\S]*?)<\/script>/)
    let scriptContent = ''
    let isScript = false
    if (match && match[1]) {
      scriptContent = match[1]
      isScript = true
    } else {
      isScript = false
    }

    const renderContent = () => {
      if (isScript) {
        return <Script strategy="idle">{`${scriptContent}`}</Script>
      } else {
        return (
          <Container noPadding fluid className={s.customContainer}>
            <div
              className={s.textWrapper}
              dangerouslySetInnerHTML={createMarkup(wysiwygText)}
            />
          </Container>
        )
      }
    }

    return <section data-sectionname="Text">{renderContent()}</section>
  }
}
